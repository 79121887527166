.popupoverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #052f5140;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
button.swal2-confirm.swal2-styled{
  display: none !important;
}
.popupForm {
  z-index: 9999;
  width: 80%;
  // position: absolute;
  height: 500px;
  // left: 50%;
  // top: 150px;
  // transform: translate(-50%);
  background: white;
  border-radius: var(--border-radius);
  padding: 0px 90px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;

  .leftside {


    .heading {
      color: var(--color-blue);
      font-size: 42px;
      line-height: 1.1em;
      font-weight: 600;
      line-height: 1em;
    }

    .para {
      color: var(--color-blue);
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;

    }

  }

  .rightside {
    position: relative;

    .cancel1 {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 0px;
      top: -51px;
      right: -76px;
      width: 30px;
      height: 30px;
      padding-top: 2px;
      padding-right: 0px;
    }

    .para1 {
      color: var(--color-blue);
      font-size: 20px;
      font-weight: 600;

    }

    .inpgrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      .inputbox {
        border: none;
        color: var(--color-blue);
        border-bottom: var(--color-blue);
        border-bottom: 2px solid var(--color-blue);
        outline: none;
        padding: 5px;
      }

      .textarea {
        grid-column: span 2;
        border: none;
        color: var(--color-blue);
        border-bottom: var(--color-blue);
        border-bottom: 2px solid var(--color-blue);
        outline: none;
        padding: 5px;
        max-height: 80px;
        min-height: 80px;
      }
    }

    button {
      margin-top: 40px;
      padding: 10px 0px;
      border-radius: 5px;
      outline: none;
      border: none;
      background: var(--color-blue);
      color: white;
      font-weight: 600;
      width: 100%;

    }
  }

}


@media only screen and (max-width: 1187px) {
  .popupForm {
    grid-template-columns: 1fr;
    height: auto;
    padding: 50px 50px;
    grid-gap: 0rem;
    top: 60px;

  }

  .popupForm .rightside .cancel1 {
    top: -177px;
    right: -40px;
  }
}

@media only screen and (max-width: 984px) {
  .popupForm .leftside .para {
    font-size: 16px;
    margin: 0px;
    margin-bottom: 5px;
  }

  .popupForm .rightside .para1 {
    margin-bottom: 5px;
    font-size: 16px;
    margin: 0px;
  }

  .popupForm .rightside .cancel1 {
    top: -137px;
  }

  .popupForm .leftside .heading {
    font-size: 34px;
  }

  .popupForm .leftside .heading {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 680px) {
  .popupForm .rightside .inpgrid {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .popupForm .rightside .cancel1 {
    top: -154px;
  }

  .popupForm .rightside .para1 {
    margin-bottom: 10px;

  }

  .popupForm .leftside .heading {
    margin-bottom: 15px;
  }

  .popupForm .rightside .inpgrid .textarea {
    grid-column: auto;
  }

  .popupForm {
    width: 90%;
  }
}



@media only screen and (max-width: 615px) {
  .popupForm .rightside .cancel1 {
    top: -147px;
    right: -33px;

  }
}

@media only screen and (max-width: 439px) {
  .popupForm .leftside .para {
    display: none;
  }

  .popupForm .rightside .para1 {
    display: none;
  }

  .popupForm .leftside .heading {
    font-size: 26px;
  }

  .popupForm .rightside .cancel1 {
    top: -105px;
    right: -40px;
  }
}

@media only screen and (max-width: 360px) {
  .popupForm {
    padding: 20px 20px;
  }

  .popupForm .rightside .cancel1 {
    top: -80px;
    right: -14px;
  }
}