.CareerBanner {
  width: 100%;
  min-height: 350px;
  background-image: url("../../assets/join.jpg");
  // background-position: center top;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 450px;

  .banner_inner {
    padding-top: 80px;
  }
}

@media screen and (min-width: 240px) and (max-width: 768px) {
  .CareerBanner {
    min-height: 400px;
    background-image: url("../../assets/join.jpg");
    background-position: center center;
    background-size: cover;

    .banner_inner {
      padding-top: 150px;
    }
  }
}