.concare {
  width: 100%;
  height: 500px;
  .concarewrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .concareleft,
    .concareright {
      position: relative;
      display: grid;
      justify-content: center;
      align-content: flex-end;
      background-size: cover;
      background-position: center;
      background-repeat: repeat;
      .lbtn {
        padding: 10px 20px;
        border: none;
        outline: none;
        font-weight: bold;
        color: white;
        background: var(--color-blue);
        border-radius: var(--border-radius);
        margin-bottom: 40px;
      }
    }
  }
}

@media only screen and (max-width: 970px) {
  .concare {
      height: auto;
    .concarewrap {
        height: auto;
      grid-template-columns: 1fr;
      .concareleft,
      .concareright {
        height: 500px;
      }
      .concareleft{
          margin-bottom: 10px;
      }
    }
  }
}
