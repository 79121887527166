.comrightarea {
    width: 100%;
    min-height: 500px;
    display: grid;
    // margin-top: 50px;

    .rightareawrap {
        width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        .rightleftwrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;

            .rrh1 {
                color: var(--color-blue);
                font-weight: 700;
                font-size: 35px;
            }

            .rrh2 {
                color: var(--back-color);
                font-weight: 500;
                font-size: 30px;

            }

            .rrp {
                text-align: justify;
                line-height: 2;
                margin-bottom: 1rem;
                font-size: 16px;

            }

            .rbtn {
                padding: 20px 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                outline: none;
                border: none;
                border-radius: 30px;
                background: var(--back-color);
                width: 160px;
                font-weight: bold;
                height: 30px;
            }
        }
    }



    .rightrightwrap {
        width: 100%;
        align-self: center;

        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1080px) {
    .comrightarea {
        height: auto;

        .rightareawrap {
            width: 80%;
        }

    }
}


@media screen and (max-width: 900px) {
    .comrightarea {
        height: auto;

        .rightareawrap {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            text-align: center;
        }

    }
}

@media screen and (max-width: 375px) {
    .comrightarea {
        height: auto;
        margin-top: 20px;

        .rightareawrap {
            grid-template-columns: 1fr;
            // grid-template-rows: repeat(2, 1fr);

            .rightleftwrap {
                .rrh1 {
                    font-size: 30px;
                }

                .rrh2 {
                    font-size: 18px;
                    margin: 10px 0px;
                }
            }
        }

    }
}