// nav.navbar.navbar-light.navbar-expand-lg {
//   position: fixed;
// }

.navbar-dark .navbar-toggler {
  box-shadow: 0px 0px 11px #052f5145 !important;
}

.navbar-dark .navbar-toggler-icon {
  filter: invert(1) !important;
}

.mobile-brand {
  display: none;
}

.navbar {
  z-index: 999;
  padding-left: 5%;
  padding-right: 5%;
  // position: absolute !important;
  width: 100%;
  background: white;


  .dropdown-menu {
    background-color: transparent;
    border: none;
    padding: 1px 0px;
  }

  li.mob-item {
    position: relative;
  }

  .dropdown-menu-mob {
    position: relative;
    float: none;
  }

  .nav-item.dropdown:hover>.dropdown-menu {
    display: block;
    margin-top: 0;
  }

  .nav-item.dropdown:focus-within>.dropdown-menu {
    display: block;
    margin-top: 0;
  }

  .dropdown-menu.clicked {
    display: none !important;
    margin-top: 0;
  }

  .dropdown-menu .inner {
    // padding-top: 1px;
    background-color: var(--color-blue);
    border-radius: var(--border-radius);

    a.dropdown-item {
      color: white !important;

      &:hover {
        background-color: #fff;
        // width: 98%;
        border-radius: 2px;
        // margin: 0 auto;
        color: var(--color-blue) !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }


  }

  .logo {
    max-width: 130px;
  }

  .right_side_area {
    display: flex;
    // background: #073153;
    // padding: 10px 18px;
    border-radius: var(--border-radius);

    .contact_details {
      display: flex;
      line-height: 35px;

      .phone {
        margin: 0 6px;
        color: var(--color-blue);
        font-weight: 600;

        a {
          color: var(--color-blue);
          font-weight: 800;
          text-decoration: none;
          //   color: white;
        }
      }
    }
  }

  .get_quote {
    background: var(--color-orange);
    color: white;

    &:hover {
      background: var(--color-blue);
      color: var(--color-orange);
    }
  }

  .socail_icons {
    display: flex;
    margin-right: 6px;
    margin-top: 3px;

    .social {
      width: 30px;
      height: 30px;
      background-color: var(--color-blue);
      border-radius: 100%;
      text-align: center;
      // padding-top: 4px;
      padding-top: 1px;
      margin: 0 3px;

      a {
        font-size: 1.1em;
        color: var(--color-white);

        &:hover {
          color: var(--color-orange);
        }
      }

      &:nth-child(1) {
        margin-left: 3px;
      }
    }
  }

  .collapse.navbar-collapse {
    justify-content: space-between;

    .navbar-brand {
      margin-right: 0 !important;
      margin-left: 4rem !important;

      &.mobile-brand {
        display: none;
      }
    }
  }

  .navbar-nav {
    // background: #073153;
    border-radius: var(--border-radius);

    .nav-item {
      a {
        color: var(--color-blue);
        font-weight: 700;

        &:hover {
          color: var(--color-orange);
        }

      }

      // &:not(:nth-child(1)){
      //     border-left: 1px solid white;

      // }
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 992px) {

  .navbar {
    margin-top: 28px;
    width: 100px;
    z-index: 9999;
    position: fixed !important;
    margin-bottom: 24px;
    background: none;
  }

  .mobile-brand {
    margin-bottom: 20px;
    display: block;
    width: 100%;
    text-align: center;
    background-color: var(--color-blue);
    background-color: white !important;
    position: fixed !important;
    z-index: 9999;
    padding-bottom: 20px !important;

    >img {
      width: 100%;
      max-width: 200px !important;
    }
  }

  .mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--color-blue);

    .mobile_nav_wrap {

      // padding: 50px 50px;
      .close_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;
        height: 50px;
        z-index: 99999;
        font-size: 2em;
        display: grid;
        align-content: center;
        justify-content: center;
      }

      .logo_area {
        width: 100%;
        text-align: center;
        height: 100px;
        display: grid;
        align-content: center;
        justify-content: center;
        box-shadow: 0px 0px 11px #052f5124;
        background-color: white;

        >.logo {
          max-width: 120px;
        }
      }

      .nav_area {
        padding: 30px 50px;
        overflow-y: scroll;
        max-height: 500px;

        ul.navbar-mob {
          list-style: none;
          padding-left: 0;

          li {
            padding: 10px 0 0 0;

            a {
              color: white;
              font-weight: 600;
              font-size: 1em;

              &:hover {
                color: var(--color-orange);
              }
            }

            &.mob-item.dropdown {
              .dropdown-toggle {
                &:after {
                  position: absolute;
                  right: 0;
                  top: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}