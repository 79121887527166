




.discover_wraper{
    width: 100vw;
    height:400px;
    background-image: url('../../assets/discover_background.jpg');

}

.discover_area{
    background-color: var(--color-blue);
    // background-color: black;
    opacity: 0.9;
}

.discover_area{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

}

.discover_area h1,p,button{
    margin: 0px;
    padding: 0px;
}

.discover_area h1{
    text-align: center;
    font-weight: 600;
}
.discover_area {
    font-weight: 500;
}

.discover_button{
    padding: 10px 20px;
    color: white;
    background-color: var(--color-orange);
    border: none;
    font-size: 1em;
    margin-top: 20px;
    border-radius: var(--border-radius);
    outline: none;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.6s ease;
    &:hover{
    background-color: var(--color-white);
    color: var(--color-blue);

        
    }
}

@media only screen and (max-width: 960px){
    .discover_area h1{
        margin-bottom: 20px;
    }
}