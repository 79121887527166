// .Features{
//   margin-top: 50px;
// }

.features_container {
  width: 100vw;
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 80px 100px;

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    width: 100%;
    height: 100%;

    .feature_para {
      margin-bottom: 30px;
      color: var(--color-blue);
    }
    p {
      color: var(--color-blue);
    }

    p {
      margin: 0px;
      padding: 0px;
      margin-bottom: 5px;
      text-align: justify;
      font-size: 16px;
    }

    h3 {
      margin: 0px;
      padding: 0px;
      color: var(--color-orange);
      font-weight: 600;
      font-size: 1.5em;
    }

    h1 {
      margin: 0px;
      padding: 0px;
      font-weight: bolder;
      color: var(--color-blue);
      font-size: 2.2em;
      margin-bottom: 20px;
    }
  }
}

.image_sec {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  img {
    width: 100%;
    max-height: 500px;
    padding: 0px 50px;
  }
}

.features_images {
  width: 180px;
  height: 180px;
  background-color: var(--color-blue);

  img {
    border-radius: var(--border-radius);
  }
  &:nth-child(1),
  &:nth-child(3) {
    img {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:nth-child(1) {
    img {
      border-bottom-left-radius: 0;
    }
  }

  &:nth-child(3) {
    img {
      border-top-left-radius: 0;
    }
  }
  &:nth-child(2),
  &:nth-child(4) {
    img {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:nth-child(2) {
    img {
      border-bottom-right-radius: 0;
    }
  }

  &:nth-child(4) {
    img {
      border-top-right-radius: 0;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .features_container {
    height: auto;
    display: grid;
    padding: 30px 0;
    .description {
      padding-right: 0px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .features_container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    padding: 50px 0px;
    .description {
      text-align: left;
      padding: 20px 50px 0px 50px;
      h3 {
        margin-top: 20px;
      }
      p {
        text-align: justify;
      }
    }
  }
  .image_sec {
    align-content: center;
    justify-content: center;
    padding-right: 0;
  }
}
@media only screen and (max-width: 375px) {
  .features_container {
    .description {
      padding: 20px 20px 0px 20px;
    }
  }
  .image_sec img{
    padding: 0px 20px
  }
  .features_container .description .feature_para{
    margin-bottom: 0px;
  }
}
