.comleftarea {
    width: 100%;
    min-height: 500px;
    display: grid;
    // margin-top: 50px;

    .leftareawrap {
        width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        .leftrightwrap {
            width: 100%;
            align-self: center;

            img {
                width: 100%;
            }
        }

        .leftleftwrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;


            .llh1 {
                color: var(--color-blue);
                font-weight: 700;
                font-size: 35px;
            }

            .llh2 {
                color: var(--back-color);
                font-weight: 500;
                font-size: 30px;
            }

            .llp {
                text-align: justify;
                line-height: 2;
                margin-bottom: 1rem;
                font-size: 16px;


            }

            .rbtn {
                padding: 20px 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                outline: none;
                border: none;
                border-radius: 30px;
                background: var(--back-color);
                width: 160px;
                font-weight: bold;
                height: 30px;
            }
        }
    }




}

@media screen and (max-width: 1080px) {
    .comleftarea {
        height: auto;

        .leftareawrap {
            width: 80%;
        }

    }
}

@media screen and (max-width: 900px) {
    .comleftarea {
        height: auto;
        margin-top: 00px;

        .leftareawrap {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            // grid-template-rows: repeat(2, 1fr);
            text-align: center;
        }
    }
}

@media screen and (max-width: 375px) {
    .comleftarea {
        height: auto;
        margin-top: 20px;

        .leftareawrap {
            grid-template-columns: 1fr;
            // grid-template-rows: repeat(2, 1fr);

            .leftleftwrap {
                .llh1 {
                    font-size: 30px;
                }

                .llh2 {
                    font-size: 18px;
                    margin: 10px 0px;
                }
            }
        }

    }
}