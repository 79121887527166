.pagenotfound {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 80vh;
    h1{
        text-align: center;
        font-weight: bold;
    }
}