.seodesc {
    height: 350px;
    overflow-y: scroll;
    width: 80%;
    margin: 0 auto;
    padding: 20px 20px 10px 20px;
    display: grid;
    text-align: justify;
    box-shadow: 0px 0px 10px #0000002e;
    border-radius: var(--border-radius);
    background-color: white;
    margin-bottom: 100px;

    &::-webkit-scrollbar {
        width: .5em;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        // outline: 1px solid slategrey;
        border-radius: 5px;
    }

    h1 {
        font-size: 33px;
        font-weight: bold;
        text-align: left;
        color: var(--color-blue);
    }

    p {
        color: var(--color-blue);
        font-size: var(--text-font-size)
    }
}


@media only screen and (max-width: 778px) {
    .seodesc {
        width: 94%;
        margin: 0 auto;
        margin-bottom: 40px;

        h1 {
            font-size: 1.8rem;
        }

    }
}