.Footer {
  background-color: var(--color-blue);
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-image: url("/assets/images/footer_vector.png");
    mix-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    opacity: 0.3;
    filter: invert(0);
    z-index: -1;
  }

  .inner {
    padding: 60px 5% 0 5%;

    .footer_row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 100px;
      color: white;

      .logo_col {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 150px;
          // margin-bottom: 20px;
        }

        .line {
          margin: 20px 0px;
        }

        .socail_icons {
          display: flex;

          .social {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 100%;
            // padding-top: 6px;
            margin: 0 8px;
            font-size: 0.8em;

            a {
              font-size: 1.3em;
              color: var(--color-blue);
            }

            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
      }

      .nav_col {
        display: flex;
        justify-content: center;

        .footer_nav {
          ul {
            list-style: none;
            padding-left: 0px;

            li {
              line-height: 2.2em;
              font-size: 1.2em;

              a {
                z-index: 9999;
                color: white;
                text-decoration: none;
              }
            }
          }
        }
      }

      .addr_col {
        .addr_row {
          display: flex;
          margin-bottom: 22px;
          cursor: pointer;

          .icon_box {
            margin-right: 14px;
            font-size: 1.5em;

            svg {
              color: var(--color-orange);
            }
          }

          .content_box {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-size: 1.4em;
              margin-bottom: 0;
            }

            p {
              font-size: 14px;
              margin-bottom: 0;
            }

            a {
              color: white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 768px) {
  .Footer {
    .inner {
      padding: 30px 5% 0 5%;

      .footer_row {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px;
        color: white;
        justify-content: center;

        .logo_col {
          width: 100%;
          overflow: hidden;
          text-align: center;

          .line {
            margin: 20px auto;
          }

          .socail_icons {
            justify-content: center;
          }
        }

        .nav_col {
          display: none;
          justify-content: center;

          .footer_nav {
            ul {
              list-style: none;

              li {
                line-height: 2.2em;
                font-size: 1.2em;
              }
            }
          }
        }

        .addr_col {
          .addr_row {
            display: grid;
            justify-content: center;
            text-align: center;

            .icon_box {
              margin-right: 0;
              font-size: 1.4em;
            }

            .content_box {

              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                font-size: 1.3em;
                margin-bottom: 0;
              }

              p {
                font-size: 14px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}