.related_products {
    width: 100%;
    display: grid;
    grid-column: span 2;


    .related_slide {
        box-shadow: 0px 4px 10px #052f5138;
        display: grid;
        align-content: center;
        justify-items: center;
        border-radius: var(--border-radius);



        a {
            color: #052f51;
            outline: none;
            text-decoration: none;
            display: grid;
            justify-items: center;

            img {
                width: 200px;
                height: 200px;
            }

            h4 {
                text-align: center;
            }
        }
    }
}