.Industry_Served {
  margin-bottom: 100px;
  .section_intro {
    display: grid;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    .title {
      h1,
      h2,
      h3,
      h4,
      h5,h6
       {
        font-size: 3em;
        color: var(--color-blue);
      }
    }
    .description {
      * {
        color: var(--color-blue);
        font-size: var(--text-font-size);
        line-height: var(--text-line-height);
      }
      p {
        margin-bottom: 30px;
        text-align: center;
        padding: 0 10px;
      }
    }
  }
}
.industry_slider {
  margin: 0 5%;
  .industry_slide {
    box-shadow: 0px 4px 10px #052f5138;
    display: grid;
    align-content: center;
    justify-items: center;
    border-radius: var(--border-radius);
    img {
      // width: 100%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,p {
      font-size: 1.1em;
      color: var(--color-blue);
      font-weight: 500;
      margin-top: 8px;
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 768px) {
  .Industry_Served {
    margin-bottom: 80px;
    .section_intro {
      width: 100%;
      display: block;
      padding: 0 5px;
      .title {
        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 2em;
        }
      }
      .description {
        p {
          text-align: justify;
        }
        * {
          font-size: var(--text-font-size);
          line-height: var(--text-line-height);
        }
      }
    }
  }
  .industry_slider {
    margin: 0 5px;
    .industry_slide {
      box-shadow: 0px 4px 10px #052f5138;
      display: grid;
      align-content: center;
      justify-items: center;
      border-radius: var(--border-radius);
      img {
        // width: 100%;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.1em;
        color: var(--color-blue);
        font-weight: 500;
        margin-top: 8px;
      }
    }
  }
}
