* {
  margin: 0;
  padding: 0;
  font-family: "Six Caps", sans-serif !important;
}
iframe {
  display: none !important;
}
:root {
  width: 100%;
  --color-white: #fff;
  --color-blue: #052f51;
  --color-orange: #f28204;
  --text-line-height: 1.4em;
  --text-font-size: 1em;
  --border-radius: 5px;
}

body,
html {
  overflow-x: hidden;
  width: 100%;
}

body {
  position: relative;
}

body .floatingicon {
  background: #3dc14f;
  z-index: 9999;
  position: fixed;
  color: white;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 40px;
  padding: 12px;
}

body .floatingicon a {
  display: flex;
  color: white;
}

.App {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Global Css */

.get_quote {
  border: none;
  width: 115px;
  height: 35px;
  border-radius: var(--border-radius);
  font-weight: 600;
  cursor: pointer;
}
