.product_box {
    box-shadow: 0px 0px 10px #0000002e;
    border-radius: var(--border-radius);
    margin-bottom: 3px;
    background-color: white;

    .inner {
        padding: 20px 20px 10px 20px;
        height: 100%;
        display: grid;

        a {
            text-decoration: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: var(--color-blue);

        }

        .desc {
            font-size: 14px;
            color: var(--color-blue);
            margin-bottom: 10px;

            >div {
                display: inline;
            }

            >span {
                font-weight: 900;
                cursor: pointer;
                margin: 0 8px;
                position: relative;

                &:not(.read_less):after {
                    content: " ...";
                    position: absolute;
                    margin-left: 2px;
                }
            }

            p {
                display: inherit;

            }
        }

        .button_box {
            display: flex;
            align-items: end;
            padding: 0px 0 10px 0;

            button,
            a {
                margin: 0 5px;
                font-size: 1em;
                font-weight: 600;
                border: none;
                width: 100%;
                height: 35px;
                border-radius: var(--border-radius);
                cursor: pointer;
                text-decoration: none;

                &:nth-child(1) {
                    background-color: var(--color-orange);
                    color: var(--color-blue);
                }

                &:nth-child(2) {
                    background-color: var(--color-blue);
                    color: white
                }
            }
        }


    }
}

.seocontent {
    h1 {
        font-size: 33px;
        font-weight: bold;
        text-align: left;
    }
}

@media only screen and (max-width: 768px) {
    .seocontent {
        width: 90% !important;
        margin: 0 auto;
        h1 {
            font-size: 26px !important;
        }

    }
}