.detailcontainer {
  width: 100%;
  padding: 60px 35px 60px 35px;
  // margin-top: 115px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Montserrat";
  font-size: 1em;
  box-shadow: 0px -3px 20px #052f516b;
  .heading {
    font-weight: 600;
    line-height: 1;
    font-size: 2em;
    padding-right: 3em;
    margin-top: 0;
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    font-size: 2rem;
    font-weight: 500;
  }
  .mainImg {
    padding: 0 35px;
    width: 100%;
    height: 400px;
    display: flex;
    text-align: right;

    .whimage {
      text-align: center;
      height: 400px;
      margin: 0 auto;
    }
  }
  .productDetails {
    padding: 0 35px;
    color: var(--color-blue);

    p {
      text-align: justify;
      margin-bottom: 10px;
    }
    .btn1 {
      padding: 8px 40px;
      background: var(--color-blue);
      color: white;
      // font-weight: bold;
      border: none;
      border-radius: var(--border-radius);
    }
  }
  .dataBtn {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    // background: rebeccapurple;
    padding-bottom: 20px;
    border-bottom: 3px solid #f1f1f1;

    a {
      width: 40%;
      // font-weight: bold;
      font-size: 1.1em;
      padding: 10px;
      background-color: var(--color-orange);
      border: none;
      color: white;
      letter-spacing: 3px;
      border-radius: var(--border-radius);
    }
  }
  .scrollCont {
    padding-top: 20px;
    grid-column: span 2;
    padding: 20px;
    color: var(--color-blue);
    margin: 0px 5%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 60px;
    border-radius: 5px;
    max-height: 300px;
    height: auto;
    overflow-y: scroll;
    p {
      margin: 10px 0px;
    }
  }
  .productDesc {
    padding-top: 20px;
    grid-column: span 2;
    padding: 20px 5%;
    color: var(--color-blue);

    ul {
      margin-left: 50px;
    }

    ol {
      margin-left: 50px;
    }

    p {
      text-align: justify;
      margin-bottom: 20px;
    }
    .btn1 {
      padding: 8px 40px;
      background: var(--color-blue);
      color: white;
      // font-weight: bold;
      border: none;
      border-radius: var(--border-radius);
      display: flex;
      justify-items: center;
      margin: 0 auto;
    }
  }

  .flx {
    display: grid;
    grid-column: span 2;
    justify-content: center;
    h2 {
      font-weight: 600;
      color: var(--color-blue);
    }
  }
}

@media only screen and (min-width: 250px) and (max-width: 750px) {
  .detailcontainer {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    margin-top: 100px;

    .mainImg {
      display: none;
    }

    .productDetails {
      margin-top: 30px;
      .heading {
        text-align: left;
      }
      .cntr {
        .btn1 {
          width: 100%;
          font-size: 1.1em;
        }
      }
    }

    .dataBtn {
      padding: 0 35px 10px;
      a {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .dataBtn {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .detailcontainer {
    padding: 0px;
    display: flex;
    flex-direction: column;
    .mainImg {
      height: 280px;

      .whimage {
        padding: 0 40px;
        height: 280px;
      }
    }
    .productDesc {
      padding: 0 40px;
    }
    .dataBtn {
      button {
        width: 78%;
      }
    }
    .cntr {
      display: flex;
      justify-content: center;
    }
    .heading {
      padding-right: 0px;
    }
    .mainImg {
      padding: 0;
    }
    .productDetails {
      padding: 0px 40px;

      p {
        text-align: justify;
        margin-bottom: 10px;
      }
    }
  }
}
