.AboutBanner{
    width:100%;
    min-height: 300px;
    background-image: url('/assets/images/aboutdesktop.png');
    // background-position: center top;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 300px;
    position: relative;

    .banner_inner{
        padding-top: 80px;
    }

    // &:after{
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 106px;
    //     top: 0;
    //     background: white;
    //     opacity: 0.9;
    //     filter: blur(31px);
    // }


}

@media screen and (min-width:240px) and (max-width:768px)  {

    .AboutBanner{
        min-height: 400px;
        background-image: url('/assets/images/abouttab.png');
        background-position: center center;
        background-size: cover;

        .banner_inner{
            padding-top: 150px;
        }
    
    }

}