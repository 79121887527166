.getq {
  width: 100%;
  height: 200px;
  background: var(--color-blue);
  color: white;
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  .getqdata {
    text-align: center;
    .getdatah2 {
      font-size: 28px;
      margin: 0px;
      padding: 0px;
      text-transform: capitalize;
    }
    .getdatah4 {
      font-size: 24px;
      margin: 0px;
      padding: 0px;
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    .btn1 {
      border-radius: var(--border-radius);
      border: none;
      outline: none;
      margin: 0 5px;
      font-weight: 600;
      padding: 11px 10px;
      text-decoration: none;
      color: var(--color-blue);
      background:var(--color-orange);

      &:hover {
        background: white;
        color: var(--color-blue);
      }
    }
  }
}
@media only screen and (max-width: 630px) {
  .getq {
    height: auto;
    padding: 10px 0px;

    .getqdata {
      .getdatah2 {
        font-size: 20px;
      }
      .getdatah4 {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .btn1 {
        font-size: 14px;
      }
    }
  }
}
