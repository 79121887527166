* {
  margin: 0px;
  padding: 0px;
}

.accordion-item {
  .accordion-header {
    .accordion-button {
      // border-radius: var(--border-radius);
      background: var(--color-blue);
      color: white;
      font-weight: bold;

      &:after {
        color: white;
        filter: invert(1);
      }
    }
  }
}

.appliedfor {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-weight: bold;
  margin-bottom: 0px;
  color: var(--color-blue);
}

.accordian {
  width: 80%;
  margin: 0px auto;
  margin-top: 50px;

  .formsubmit {
    background: var(--color-blue);
    padding: 5px 20px;
    border-radius: var(--border-radius);
    color: white;
  }

  .acchead {
    text-align: center;
    font-weight: bold;
    color: var(--color-blue);
  }

  .acctitle {
    background: var(--color-blue);
  }
}

.contactusformcont {
  width: 100%;
  // min-height: 600px;
  margin: 30px 0px;
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .contactforminner {
    width: 80%;
    min-height: 500px;
    max-height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .contactinnerleft {
      .image {
        width: 100%;
        height: 100%;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
    }

    .contactinnerright {
      // newchanges
      // background: var(--color-blue);
      // background: none;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      color: white;
      padding: 40px 30px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 10px #0000002e;
      border-radius: var(--border-radius);
      background: #85838326;

      .ali {
        display: grid;
        height: 100%;
        align-items: center;
        grid-gap: 1rem;
      }

      .formfields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        color: var(--color-blue);

        .formfield {
          margin-bottom: 0;


          p {
            font-size: 1.1em;
            margin-bottom: 5px;
            color: var(--color-blue);
            font-weight: 500;

          }

          input {
            width: 100%;
            padding: 5px;
            border-radius: var(--border-radius);
            border: none;
            box-shadow: 0px 0px 4px #052f512e;
          }
        }
      }

      .textareasec {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        color: var(--color-blue);

        p {
          font-size: 1.1em;
          margin-bottom: 5px;
          color: var(--color-blue);

        }

        textarea {
          width: 100%;
          height: 100px;
          border-radius: var(--border-radius);
          padding: 5px;
          color: var(--color-blue);

        }
      }
    }

    .contactbtn {
      font-size: 16px;
      padding: 8px 30px;
      background: var(--color-blue);
      color: white;
      font-weight: bold;
      border-radius: var(--border-radius);
      border: none;
      outline: none;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width:958px) {
  .contactusformcont .contactforminner {
    grid-template-columns: 1fr;

  }

  .contactusformcont .contactforminner .contactinnerleft {
    display: none;
  }
}

@media only screen and (max-width:636px) {
  .contactusformcont .contactforminner .contactinnerright .formfields {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width:436px) {
  .contactusformcont{
    display: grid;
  }
}
@media only screen and (max-width: 376px) {
  .contactusformcont {
    display: block;
    margin-left: 6px;

    .contactusformcont .contactforminner .contactinnerright {
      border-radius: 5px !important;
    }
  }
}