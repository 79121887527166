.SatisfiedClients {
    width: auto;
    // background-color: #F5F5F5;
    background-image: url('/assets/images/dot.png');

    .supplementaryHeader {
        display: grid;
        grid-template-columns: repeat(2, 600px);
        justify-content: center;
        gap: 3rem;
        padding: 100px 0px;
        margin: 0px 5%;

        .imageContainer {
            img {
                width: 100%;
                border-radius: 35px;
            }
        }

        .contentContainer {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            justify-content: center;

            .miniTitle {
                display: flex;
                align-items: center;
                gap: .6rem;

                img {
                    width: 25px;
                }

                p {
                    margin: 0px;
                    color: var(--color-blue);
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.2em;
                    letter-spacing: 0.1em;
                }
            }

            .title {
                font-size: 50px;
                line-height: 1.1em;
                color: var(--color-blue);
                margin-bottom: 0;

                span {
                    font-weight: 600;
                }

            }

            .description {
                font-size: 19px;
                font-weight: 500;
                line-height: 1.7em;
                letter-spacing: 0px;
                color: #81848A;
                margin-bottom: 0rem;
            }


            .twoColsection {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                .ratingCont {
                    background: #f5f5f5;
                    padding: 50px;
                    border-radius: 40px;

                    .rating {
                        background-image: url(/assets/images/test.jpg);
                        font-size: 80px;
                        font-weight: 900;
                        line-height: 1.2em;
                        letter-spacing: -0.01em;
                        color: transparent;
                        background-clip: text;
                        text-align: center;
                        margin-bottom: 0px;
                    }

                    .starRating {
                        color: #ff9900;
                        font-size: 18px;
                        display: flex;
                        gap: .3rem;
                        justify-content: center;
                        margin: 5px 0px;
                        margin-top: 10px;
                    }

                    .genuine {
                        color: #81848a;
                        font-size: 16px;
                        text-align: center;
                        margin-top: 20px;
                    }
                }

                .listContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 1.5rem;

                    span {
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.7em;
                        letter-spacing: 0px;
                        color: #81848A;
                        margin-bottom: 0rem;

                        img {
                            margin-right: 6px;
                        }
                    }

                    .btn {
                        text-decoration: none;
                        background: var(--color-orange);
                        width: max-content;
                        padding: 8px 30px;
                        font-size: 16px;
                        font-weight: 600;
                        color: white;
                        border-radius: 8px;

                        &:hover {
                            background: var(--color-blue);
                        }
                    }
                }
            }

            .sliderContainer {
                position: relative;

                .item {
                    p {
                        font-size: 19px;
                        font-weight: 500;
                        line-height: 1.7em;
                        letter-spacing: 0px;
                        color: #81848A;
                        margin-bottom: 1.5rem;
                    }

                    .user {
                        display: flex;
                        gap: 1rem;

                        img {
                            width: 70px;
                            height: 70px;
                            border-radius: 10px;
                        }

                        .userInfo {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            p {
                                margin: 0px;
                                line-height: 1.1em;
                            }

                            .name {
                                color: black;
                                font-size: 22px;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }

            .btnWrapper {
                width: auto;
                height: auto;
                position: absolute;
                bottom: 30px;
                right: 0;
                display: flex;
                gap: 1rem;
                z-index: 555;

                .arrowWrapper {
                    background: var(--color-orange);
                    color: white;
                    padding: 20px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .SatisfiedClients .supplementaryHeader {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 999px) {
    .SatisfiedClients .supplementaryHeader {
        grid-template-columns: 1fr;
    }

}

@media screen and (max-width: 600px) {
    .SatisfiedClients .supplementaryHeader .contentContainer .twoColsection {
        grid-template-columns: 1fr;
    }

    .SatisfiedClients .supplementaryHeader {
        padding-top: 20px;
    }

    .SatisfiedClients .supplementaryHeader .contentContainer .title {
        font-size: 38px;
    }

    .SatisfiedClients .supplementaryHeader .contentContainer .description {
        font-size: 17px;
    }

    .SatisfiedClients .supplementaryHeader .contentContainer .twoColsection .listContainer span {
        font-size: 17px;
    }
}