.search {
  width: 100%;
  margin: 100px auto 0;
  background: #f282040d;
  padding: 0 5%;


  .loader_container{
    display: grid;

    align-content: center;
    justify-content: center;

  }
  

  .SearchBanner {
    height: auto;
    display: grid;
    align-content: center;
    border-bottom: 1px solid #0731532b;
    .search_field {
      width: 41%;
      box-shadow: 0px 0px 10px #00000057;
      position: relative;
      padding: 12px;
      border-radius: var(--border-radius);
      margin: 15px 0;
      background: white;

      input {
        width: 100%;
        border: none;
        outline: none;
        color: var(--color-blue);
      }
      button {
        position: absolute;
        right: 20px;
        background-color: transparent;
        border: none;
        font-size: 26px;
        line-height: 1em;
        top: 13px;
        color: var(--color-blue);
        cursor: pointer;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 2em;
      color: var(--color-blue);
      font-weight: 600;
      margin: 0;
      padding: 0;
      span {
        font-weight: 500;
      }
    }
    h5 {
      a {
        text-decoration: none;
        color: black;
        cursor: pointer;
        color: var(--color-orange);
      }
    }
  }

  .searchdiv {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1rem;
    padding: 20px 0px 10px 0px;

    .nothing_found {
      h6 {
        font-size: 2em;
        color: var(--color-blue);
        font-weight: 600;
        margin: 0;
        padding: 0;
        padding-bottom: 6px;
      }
      .ancr {
        text-decoration: none;
        p {
          color: var(--color-orange);
        }
      }

      p {
        font-size: 1.2em;
        color: var(--color-blue);
        font-weight: 500;
      }

      .search_field {
        width: 80%;
        box-shadow: 0px 0px 10px #00000057;
        position: relative;
        padding: 12px;
        border-radius: var(--border-radius);
        margin: 15px 0;
        background: white;

        input {
          width: 100%;
          border: none;
          outline: none;
          color: var(--color-blue);
        }
        button {
          position: absolute;
          right: 20px;
          background-color: transparent;
          border: none;
          font-size: 26px;
          line-height: 1em;
          top: 13px;
          color: var(--color-blue);
          cursor: pointer;
        }
      }
    }
  }
}
