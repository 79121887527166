.ReviewSliderWrapper {
    width: 100%;
    background: #000;
    background: var(--color-blue);
    margin-bottom: 100px;

    .reviewInner {
        width: 100%;
        height: 100%;
        // background-image: url('/assets/images/map.png');

        .inner {
            width: 1250px;
            margin: 0 auto;
            padding: 100px 0px;

            .contentContainer {
                width: 650px;
                margin: 0 auto;
                color: white;

                p {
                    margin: 0px;
                    text-align: center;
                }

                .title {
                    margin-bottom: 5px;
                    font-size: 35px;
                    line-height: 45px;
                    font-weight: 600;
                    color: var(--color-orange);
                }

                .description {
                    font-size: 18px;
                }
            }

            .reviewSlider {
                color: white;
                margin-top: 30px;

                .industry_slide {

                    .reviewCont {
                        position: relative;
                        font-size: 18px;

                        .icon {
                            color: var(--color-orange);
                            font-size: 26px;
                        }

                        .rightIcon {
                            position: absolute;
                            right: 0;
                            bottom: -30px;
                        }
                    }

                    .userInfo {
                        p {
                            margin: 0px;
                        }

                        .userName {
                            // color: var(--color-orange);
                            font-weight: 600;
                            font-size: 20px;
                        }
                    }
                }
            }

        }

    }
}


@media screen and (max-width: 1350px) {
    .ReviewSliderWrapper .reviewInner .inner {
        width: 90%;
    }
}

@media screen and (max-width: 999px) {
    .ReviewSliderWrapper .reviewInner .inner .contentContainer {
        width: 100%;
    }

    .ReviewSliderWrapper .reviewInner .inner .reviewSlider .industry_slide .reviewCont .icon {
        font-size: 22px;
    }

    .userInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 17px;
    }

    .reviewCont p {
        text-align: center;
    }

    .ReviewSliderWrapper .industry_slide {
        padding: 0px !important;
    }
}