* {
  margin: 0px;
  padding: 0px;
}

.contactus_formcont {
  width: 100%;
  min-height: 600px;
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 50px #8888883b;
  margin-bottom: 80px;
  // margin-top: 110px;
  padding: 30px 0px;

  .contactforminner {
    width: 80%;
    // min-height: 900px;
    max-height: auto;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);

    // align-items: c;
    .contactinnerleft {
      .iconwraper {
        display: grid;
        margin-left: 40px;
        // margin-top: 60px;

        .contactinnerinfo {
          margin-bottom: 40px;
          width: 70%;

          //   text-align: justify;
          .iconsec {
            display: flex;
            align-items: center;
            font-size: 20px;
            width: 80%;

            .data {
              font-size: 16px;
              font-weight: 500;

              a {
                text-decoration: none;
                cursor: pointer;
                color: black;
              }

              .extradata {
                font-size: 20px;
                display: block;
                font-weight: 700;
              }
            }

            //   background: rebeccapurple;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              font-size: 24px;
              color: #fff;
              height: 50px;
              margin-right: 20px;
              width: 50px;
              min-width: 50px;
              // line-height: 63px;
              background-color: var(--color-orange);
            }
          }
        }
      }

      .contacthedi {
        font-size: 40px;
        font-weight: bolder;
        margin-top: 20px;
        margin-bottom: 40px;

        .contactyou {
          color: var(--color-orange);
        }
      }

      .subh {
        font-size: 18px;
        font-weight: bold;
        display: flex;

        .line {
          //   border-bottom: 2px solid black;
          width: 60px;
          color: var(--color-orange);
          background: var(--color-orange);
        }
      }
    }

    .contactinnerright {
      box-shadow: 0px 0px 10px #0000002e;
      border-radius: var(--border-radius);
      background: #85838326;
      // background: var(--color-blue);
      // border-radius: var(--border-radius);
      color: var(--color-blue);
      padding: 40px 30px;

      .ali {
        display: grid;
        height: 100%;
        align-items: center;
        grid-gap: 1rem;
      }

      .formfields {
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        .formfield {
          margin-bottom: 0;

          p {
            font-size: 1.1em;
            margin-bottom: 5px;
            margin-top: 5px;
          }

          input {
            width: 100%;
            padding: 5px;
            border-radius: var(--border-radius);
            border: none;
            box-shadow: 0px 0px 4px #052f512e;
            outline: none;
          }
        }
      }

      .textareasec {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;

        p {
          font-size: 1.1em;
          margin-bottom: 5px;
        }

        textarea {
          width: 100%;
          height: 100px;
          border-radius: var(--border-radius);
          border: none;
          box-shadow: 0px 0px 4px #052f512e;
          padding: 5px;

        }
      }
    }

    .contactbtn {
      font-size: 16px;
      padding: 8px 30px;
      background: var(--color-blue);
      color: white;
      font-weight: bold;
      border-radius: var(--border-radius);
      border: none;
      outline: none;
    }
  }
}

@media screen and (max-width: 1350px) {
  .contactus_formcont {
    .contactforminner {
      width: 95%;
    }
  }
}

@media screen and (max-width: 1130px) {
  .contactus_formcont {
    margin-bottom: 30px;
    padding: 20px 0px;

    .contactforminner {
      .contactinnerright {
        .formfields {
          grid-template-columns: 1fr;
          grid-gap: 0;
        }
      }

      .contactinnerleft {
        .contacthedi {
          //   font-size: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .contactus_formcont {
    .contactforminner {
      grid-template-columns: 1fr;
      width: 60%;

      .contactinnerright {
        .formfields {
          grid-template-columns: 1fr;
          grid-gap: 0;
        }
      }

      .contactinnerleft {
        display: grid;
        justify-content: center;

        .iconwraper {
          margin-left: 0px;

          .contactinnerinfo {
            .iconsec {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contactus_formcont {
    margin-top: 100px;

    .contactforminner {
      grid-template-columns: 1fr;
      width: 100%;
      margin-bottom: 30px;

      .contactinnerleft {
        //   padding-left: 20px;
        padding: 20px;

        .contacthedi {
          font-size: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .contactus_formcont .contactforminner .contactinnerleft .iconwraper .contactinnerinfo {
    width: 100%;
  }

  .contactus_formcont .contactforminner .contactinnerleft .iconwraper .contactinnerinfo {
    margin-bottom: 10px;
  }

  .contactus_formcont .contactforminner .contactinnerleft .contacthedi {
    margin-bottom: 10px;
  }
}