.banner {
    width: 100%;
    min-height: 600px;
    background-image: url('/assets/images/main-banner.webp');
    top: 0;
    display: grid;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 5px #0000002e;
    margin-bottom: 100px;

    .banner_inner {}
}

@media screen and (min-width:240px) and (max-width:768px) {

    .banner {
        // background-image: url('../../../public/assets/images/main-banner.webp');
        // top: 0;
        // display: grid;
        // align-items: center;
        // padding-left: 5%;
        // padding-right: 5%;
        // background-position: top right;
        // background-repeat: no-repeat;
        // background-size: cover;
        // box-shadow: 0px 0px 5px #0000002e;
        // margin-bottom: 60px;
        min-height: 500px;
        padding-left: 0%;
        padding-right: 0%;

        // position: relative;
        .banner_inner {
            width: 100%;
            height: 100%;
            display: grid;
            align-content: center;

        }
    }
}