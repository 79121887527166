.TopProducts {
  margin-bottom: 60px;

  .section_intro {
    display: grid;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    .title {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 3em;
        color: var(--color-blue);
      }
    }

    .desc {
      p {
        text-align: center;
      }

      * {
        color: var(--color-blue);
        font-size: var(--text-font-size);
        line-height: var(--text-line-height) 1.4em;
      }
    }
  }

  .products_list {
    margin-top: 30px;

    .ProductRow {
      &:nth-last-child(1) {
        padding-top: 60px;
        padding-bottom: 100px;
        // height: 300px !important;
        box-shadow: inset 0px -33px 17px 3px black;
      }
    }
  }

  .explore {
    margin: 0px;
    margin-top: 20px;
    height: 50px;
    font-size: 22px;

    .faicon {
      background: var(--color-blue);
      border-radius: 30px;
      padding: 5px;
      margin-left: 5px;
      width: 25px;
      height: 25px;
      color: white;
      transition: all 0.5s;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5%;
      font-weight: bold;
      color: var(--color-blue);
      text-decoration: none;
      transition: all 0.5s;

      &:hover {
        color: var(--color-orange);
      }

      &:hover .faicon {
        background: var(--color-orange);
      }
    }
  }
}

.rel {
  position: relative;

  .arrow {
    width: 60px;
    height: 82px;
    position: absolute;
    /* don't forget set container to relative */
    left: 49%;
    margin-left: 0px;
    bottom: 5%;
    z-index: 999;
  }

  /* set arrow styles */
  .arrow path {
    stroke: #fff;
    stroke-width: 5px;
    fill: transparent;
    animation: down 2s infinite;
  }

  /* arrow keyframe animation */
  @keyframes down {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  /* arrow animation delay */
  .arrow path.a1 {
    animation-delay: -1s;
  }

  .arrow path.a2 {
    animation-delay: -0.5s;
  }

  .arrow path.a3 {
    animation-delay: 0s;
  }
}

@media screen and (min-width: 240px) and (max-width: 768px) {
  .TopProducts {
    .section_intro {
      width: 100%;
      padding: 0 5px;

      .title {

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 2em;
        }
      }

      .desc {
        p {
          text-align: justify;
          padding: 10px;
        }

        * {
          font-size: var(--text-font-size);
          line-height: var(--text-line-height);
        }
      }
    }
  }
}