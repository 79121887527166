.ProductsMainArea {
  width: 100%;
  height: -webkit-fill-available;
  margin-bottom: 50px;
  margin-top: 50px;

  .loader_container {
    display: grid;
    align-content: center;
    justify-content: center;
  }

  .seocontent {
    // margin: 0 5%;
    padding: 20px 20px 10px 20px;
    height: 100%;
    width: 99%;
    display: grid;
    text-align: justify;
    box-shadow: 0px 0px 10px #0000002e;
    border-radius: var(--border-radius);
    margin-bottom: 3px;
    background-color: white;
    margin-top: 0.7em;
    height: 350px;
    overflow-y: scroll;
    h1 {
      color: var(--color-blue);
    }
    p {
      color: var(--color-blue);
      font-size: var(--text-font-size);
    }
  }

  > .inner {
    display: grid;
    grid-template-columns: 330px 1fr;
    grid-gap: 10px;
    position: relative;
    z-index: 1;

    .category_side {
      // height:500px;
      box-shadow: 0px 3px 5px #0000002e;
      border-radius: var(--border-radius);

      > .inner {
        padding: 0 5%;

        .tabs__nav {
          padding: 10px 0 30px;
          list-style: none;

          .tabs__item {
            padding: 0 10px;
            cursor: pointer;
            transition: all 0.4s ease;
            font-size: 1em;
            border-radius: var(--border-radius);
            margin: 2px 0;

            &.active {
              background-color: var(--color-blue);
              color: white;
              border-top: 5px solid var(--color-orange);
              padding: 2px 10px;
            }

            &:hover {
              background-color: #052f5124;
              color: var(--color-blue);
              padding: 2px 10px;
            }

            .tabs__button {
              background: rgba(0, 0, 0, 0);
              padding: 4px;
              color: var(--headline);
              box-shadow: none;
              outline: none;
              border: none;
              cursor: pointer;
              font-size: 1em;
              display: block;
              position: relative;
              font-family: inherit;
              font-weight: 500;

              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background: var(--activeColor);
                opacity: 0;
                visibility: hidden;
                transition: visibility 0s, opacity 0.4s ease-in-out;
              }
            }
          }
        }
      }
    }

    .product_side {
      // height:500px;
      height: auto;

      .tabs__content {
        display: grid;
        grid-template-columns: repeat(2, 49%);
        grid-gap: 0.7em;
        width: 100%;
        background: #00000005;
        border-radius: var(--border-radius);
        // margin-bottom: 40px;

        .quoteform {
          display: grid;
          grid-column: span 2;
          width: 100%;
          background: rebeccapurple;
        }
      }
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 768px) {
  .ProductsMainArea {
    > .inner {
      grid-template-columns: repeat(1, 100%);
      grid-gap: 0;

      .category_side {
        // height:500px;

        > .inner {
          .tabs__nav {
            display: flex;
            width: 100%;
            overflow-x: scroll;
            margin-bottom: 0;

            .tabs__item {
              .tabs__button {
                width: max-content;
              }

              &.active {
                border-top: none;
              }

              &:hover {
                background-color: #052f5124;
                color: var(--color-blue);
                padding: 2px 10px;
              }
            }
          }
        }
      }

      .product_side {
        margin: 15px 0;

        .tabs__content {
          grid-template-columns: repeat(1, 100%);
          grid-gap: 15px;
          width: 100%;
          padding: 0 5%;

          .button_box {
            justify-content: flex-start;

            button.get_quote {
              margin: 0 5px;
              font-size: 1em;
              font-weight: 600;

              &:nth-child(1) {
                margin-left: 0;
                background-color: var(--color-orange);
                color: var(--color-blue);
              }

              &:nth-child(2) {
                background-color: var(--color-blue);
                color: white;
              }
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: var(--color-blue);
          }
        }
      }
    }
  }
  .ProductsMainArea .seocontent {
    height: 250px;
  }
}
